.status-bar {
    box-sizing: border-box;
    position: fixed;
    height: 7.5vh;
    left: 0;
    top: 0;
    width: 100%;
    font-size: 3.6vh;
    padding: 0;
    color: var(--status-bar-text-color);
    background: var(--status-bar-bg);
    border-bottom: 1px solid var(--status-bar-border-color);
}

.status-bar .site-link {
    width: 100%;
    height: 7vh;
    padding-top: 0.5vh;
    text-align: center;
}

.portrait .status-bar .site-link {
    display: none;
}

.status-bar .site-link a,
.status-bar .site-link a:hover,
.status-bar .site-link a:active {
    display: inline-block;
    background-color: transparent;
    border: none;
    line-height: 1;
    padding: 0;
    outline: none;
}

.status-bar .site-link svg {
    display: block;
    width: 38vh;
}

.status-bar-buttons {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
}

.status-bar button {
    width: 6vh;
    height: 6vh;
    background-color: transparent;
    margin: 0.8vh 0.8vh 0 0;
    padding: 0.5vh;
    border: none;
    border-radius: 0.2em;
    opacity: 0.6;
}

.portrait .status-bar button {
    margin-right: 0;
    padding: 0.3vh;
}

.status-bar button:hover,
.status-bar button:focus {
    background-color: var(--status-bar-button-hover-color);
    opacity: 1;
}

.status-bar .button-icon use,
.status-bar .site-domain-image use {
    fill: var(--status-bar-text-color);
}

.site-link .site-domain-image {
    opacity: 0.6;
}

.site-link .site-domain-image:hover,
.site-link .site-domain-image:focus {
    opacity: 1;
}

#timer {
    position: absolute;
    left: 0;
    top: 0;
}
